/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import urlJoin from 'url-join';
import config from '@/config';
import { jsonHeaders } from '@/utils';

export const getTransactionById = async (
  id,
) => {
  const response = await fetch(urlJoin(
    config.api.node.url,
    config.api.node.transactionById,
    id,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};

export const getGameResultById = async (
  id,
) => {
  const response = await fetch(urlJoin(
    config.api.node.url,
    config.api.node.data,
    config.dapp.address,
    id,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};

export const broadcastTx = async (tx) => {
  const response = await fetch(urlJoin(
    config.api.node.url,
    config.api.node.broadcast,
  ), {
    method: 'POST',
    headers: jsonHeaders,
    body: tx,
  });

  return response.json();
};

export const getAddressBalanceInfo = async (
  address,
) => {
  const response = await fetch(urlJoin(
    config.api.node.url,
    config.api.node.balance,
    address,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};
