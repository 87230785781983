import { render, staticRenderFns } from "./BetMultiplierChooser.vue?vue&type=template&id=6d1591c5&scoped=true&"
import script from "./BetMultiplierChooser.vue?vue&type=script&lang=js&"
export * from "./BetMultiplierChooser.vue?vue&type=script&lang=js&"
import style0 from "./BetMultiplierChooser.vue?vue&type=style&index=0&id=6d1591c5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1591c5",
  null
  
)

export default component.exports