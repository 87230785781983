import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const LANGUAGE_CODE_DICTIONARY = {
  'en-US': 'en',
  'en-GB': 'en',
  ru: 'ru',
  en: 'en',
};

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys()
    .forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
  return messages;
}

const firstSupportedLanguageCode = localStorage.getItem('language') || navigator.languages.find(
  languageCode => languageCode in LANGUAGE_CODE_DICTIONARY,
);

export default new VueI18n({
  locale: LANGUAGE_CODE_DICTIONARY[firstSupportedLanguageCode] || 'en',
  fallbackLocale: LANGUAGE_CODE_DICTIONARY[firstSupportedLanguageCode] || 'en',
  messages: loadLocaleMessages(),
});
