<template>
  <div
    id="player-address"
    :class="['player-address', this.className]"
    :data-tooltip="isCopied ? $t('message.copied') : ''"
    :data-clipboard-text="address"
    @click="copy"
  >
    {{ shortAddress }}

    <check-icon v-if="isCopied"></check-icon>
    <icon-copy v-else></icon-copy>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getShortAddress } from '@/utils/helpers/getShortAddress';
import IconCopy from './icons/IconCopy.svg';
import CheckIcon from './icons/Check.svg';

export default {
  components: {
    IconCopy,
    CheckIcon
  },
  props: {
    className: String,
  },
  data() {
    return {
      isCopied: false,
    }
  },
  computed: {
    ...mapGetters({
      address: 'playerAddress',
    }),
    shortAddress() {
      return getShortAddress(this.address);
    },
  },
  methods: {
    copy() {
      new Clipboard('#player-address');
      this.showCopied();
    },
    showCopied() {
      this.isCopied = true;
      setTimeout(() => this.isCopied = false, 3000);
    },
  }
}
</script>

<style scoped>
.player-address {
  width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
  line-height: 14px;
  color: white;
  position: relative;
}

.player-address::before {
  content: attr(data-tooltip);
  position: absolute;
  color: #31BEA2;
  top: 100%;
  right: 0;
  background-color: transparent;
}
</style>
