const testnetTickerIdMap = {
  WAVES: 'WAVES',
  XTN: '25FEqEjRkqK6yCkiT7Lz6SAYz7gUFCtxfCChnrVFD5AT',
        
};

const mainnetTickerIdMap = {
  WAVES: 'WAVES',
  XTN: 'DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p',

};

const chainIdAssetsMap = {
  T: testnetTickerIdMap,
  W: mainnetTickerIdMap,
};

const getAssetsByChainId = (chainId) => {
  const assets = chainIdAssetsMap[chainId];

  return [
    {
      contractId: 0,
      decimals: 8,
      id: assets.WAVES,
      name: 'Waves',
      ticker: 'WAVES',
      fee: 0.005,
    },
    {
      contractId: 1,
      decimals: 6,
      id: assets.XTN,
      name: 'XTN',
      ticker: 'XTN',
      fee: 0.05,
    },
  ];
};

export const assets = getAssetsByChainId(process.env.API_NETWORK || process.env.VUE_APP_API_NETWORK || 'W');

export const assetsRecordById = assets
  .reduce((record, asset) => ({ ...record, [asset.id]: asset }), {});

export const assetsRecordByContractId = assets
  .reduce((record, asset) => ({ ...record, [asset.contractId]: asset }), {});

export const getAssetByName = name => assets.find(x => x.name.toUpperCase() === name.toUpperCase());
