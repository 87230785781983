<template>
  <div
    :class="[!!address && !useDefaultStyles ? '' : 'connect-or-action', this.className]"
    @click="!!address ? onAction() : connectWallet()"
  >
    <slot v-if="!!address"></slot>

    <span v-else>
      {{ $t('message.connectWallet.title') }}
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as Popups from '@/utils/popups';

export default {
  props: {
    className: String,
    useDefaultStyles: Boolean
  },
  computed: {
    ...mapGetters({
      address: 'playerAddress',
    }),
    title() {
      return !!this.address ? 'Play' : 'Connect Wallet';
    },
  },
  methods: {
    ...mapActions({
      login: 'login',

      setPopupVisibility: 'setPopupVisibility',
    }),
    onAction() {
      this.$emit('onAction');
    },
    connectWallet() {
      this.setPopupVisibility({
        popup: Popups.CONNECT_WALLET,
        visible: true,
      })
    },
  }
};
</script>

<style scoped>
.connect-or-action {
  width: fit-content;
  color: white;
  border-radius: 8px;
  padding: 6px 14px;
  background: linear-gradient(0deg, rgb(10, 2, 151) 0%, rgba(22,80,204,1) 50%, rgba(0,212,255,1) 100%);
  cursor: pointer;
}
</style>