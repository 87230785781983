<template>
  <div class="last-games">
    <last-games-only-me></last-games-only-me>
    <table>
      <thead>
        <tr>
          <th>{{$t('message.lastGames.player')}}</th>
          <th>{{$t('message.lastGames.bet')}}</th>
          <th>{{$t('message.lastGames.won')}}</th>
        </tr>
      </thead>
      <tbody is="transition-group" name="list">
        <template v-for="(game, id) in statsLastGamesFormatted">
          <tr :class="{odd: id % 2 === 0}" :key="game.smartAddress" @click="showPopup(game)">
            <td class="data centered">{{game.playerAddress}}</td>
            <td class="data centered">
              {{game.bet}}
              <div class="icon" :class="`icon__${game.icon}`"></div>
            </td>
            <td class="data centered" v-if="game.serverWinner">
              <span style="opacity: 0.5">&#8212;</span>
            </td>
            <td class="data centered" v-else>
              {{game.cleanWonAmount}}
              <div class="icon" :class="`icon__${game.icon}`"></div>
            </td>
          </tr>
          <tr class="spacer" :key="`${game.smartAddress}-space`">
            <td class="spacer" colspan="5"></td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  thumbnails,
  addressTruncate,
  Popups,
  States,
} from '@/utils';
import LastGamesOnlyMe from '@/components/mobile/LastGamesOnlyMe.vue';
import config from '@/config';
import urlJoin from 'url-join';

export default {
  components: {
    LastGamesOnlyMe,
  },
  computed: {
    ...mapGetters({
      statsLastGames: 'statsCurrentLastGames',
    }),
    statsLastGamesFormatted() {
      const arr = Array.from(this.statsLastGames)
        .slice(0, 10);
      return arr.map((game) => {
        const serverWinner = game.winner !== States.PLAYER;
        const playerChoice = parseInt(game.playerChoice, 10);
        const serverChoice = parseInt(game.serverChoice, 10);
        const [thumbnailPlayer, thumbnailServer] = thumbnails(serverWinner, serverChoice);
        return {
          playerAddress: addressTruncate(game.playerAddress, 5, 5, '***'),
          playerAddressLink: urlJoin(
            config.api.explorer.url,
            config.api.explorer.address,
            game.playerAddress,
          ),
          playerScore: game.playerScore,
          ratingPoints: game.ratingPoints,
          smartAddress: game.smartAddress,
          smartAddressLink: urlJoin(
            config.api.explorer.url,
            config.api.explorer.address,
            config.dapp.address,
          ),
          cleanWonAmount: game.cleanWonAmount.toFixed(2),
          bet: game.bet,
          betAssetId: game.betAssetId,
          icon: game.betAssetId === 0 ? 'WAVES' : 'XTN',
          thumbnailPlayer,
          thumbnailServer,
          serverWinner,
          playerChoice,
          serverChoice,
        };
      });
    },
  },
  mounted() {
    this.setLastGamesOnlyMe(false);
  },
  methods: {
    ...mapActions({
      setGameInformation: 'setGameInformation',
      setPopupVisibility: 'setPopupVisibility',
      setLastGamesOnlyMe: 'setLastGamesOnlyMe',
    }),
    showPopup(game) {
      this.setGameInformation(game);
      this.setPopupVisibility({
        popup: Popups.GAME_INFORMATION,
        visible: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
$td-border-radius: 0.2vw;

.icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.icon__WAVES {
  background-image: url("../../assets/images/wave-small.png");
}
.icon__XTN {
  background-image: url("../../assets/images/XTN_icon.png");
}

.last-games {
  .wave {
    width: auto;
  }

  table {
    width: 100%;
    font-weight: bold;
    font-family: Roboto, sans-serif;
    font-style: italic;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      text-align: center;
      font-size: 12px;
      text-transform: capitalize;
      color: rgb(125, 202, 250);

      tr th {
        padding: 10px 0px;
        &:nth-child(1) {
          width: 45%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: calc((100% - 45%) / 2);
        }
      }
    }

    tbody {
      text-align: justify;
      font-size: 14px;
      color: white;

      &:hover {
        cursor: pointer;
      }

      tr {
        transition: all 0.5s;

        td {
          padding: 7px 0px;

          &.spacer {
            padding: 0.5px 0px;
          }

          &.centered {
            text-align: center;
          }
        }

        &:hover:not(.spacer) {
          transform: translate(-0.5vw);
        }

        td:first-child:not(.spacer) {
          border-bottom-left-radius: $td-border-radius;
          border-top-left-radius: $td-border-radius;
        }

        td:last-child:not(.spacer) {
          border-bottom-right-radius: $td-border-radius;
          border-top-right-radius: $td-border-radius;
        }
      }

      tr.odd:not(.spacer) {
        background-color: rgba(27, 56, 112, 0.8);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      }

      tr:not(.spacer) {
        background-color: rgba(6, 29, 71, 0.8);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .caption {
    font-size: 1vw;
    margin-bottom: 1vw;
  }

  @media (min-aspect-ratio: 16/9) {
    left: calc((16 / 9) * 3vh);
    bottom: calc((16 / 9) * 2vh);

    .caption {
      font-size: calc((16 / 9) * 1vh) !important;
      margin-bottom: calc((16 / 9) * 1vh) !important;
    }

    table {
      thead {
        font-size: calc((16 / 9) * 0.8vh);

        tr th {
          padding: calc((16 / 9) * 0.3vh) calc((16 / 9) * 0.5vh);
        }
      }

      tbody {
        text-align: justify;
        font-size: calc((16 / 9) * 0.75vh);

        tr {
          td {
            padding: calc((16 / 9) * 0.2vh) calc((16 / 9) * 0.5vh);
          }

          td:first-child {
            border-bottom-left-radius: $td-border-radius;
            border-top-left-radius: $td-border-radius;
          }

          td:last-child {
            border-bottom-right-radius: $td-border-radius;
            border-top-right-radius: $td-border-radius;
          }
        }
      }
    }
  }
}
</style>
