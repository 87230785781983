// Application config
//
// Environment vars should be defined by following method:
// https://cli.vuejs.org/guide/mode-and-env.html

export default {
  app: {
    name: 'Coin Flip',
  },
  api: {
    network: process.env.API_NETWORK || process.env.VUE_APP_API_NETWORK,
    gameId: 'COIN_FLIP',
    providerWebIframeUrl: process.env.PROVIDER_IFRAME_URL || process.env.VUE_APP_PROVIDER_IFRAME_URL,
    node: {
      url: process.env.API_NODE_URL || process.env.VUE_APP_API_NODE_URL,
      data: '/addresses/data',
      transactionById: '/transactions/info',
      broadcast: '/transactions/broadcast',
      balance: '/addresses/balance/details',
    },
    explorer: {
      url: process.env.API_EXPLORER_URL || process.env.VUE_APP_API_EXPLORER_URL,
      address: '/address',
    },
    backend: {
      url: process.env.API_BACKEND_URL || process.env.VUE_APP_API_BACKEND_URL,
      startGameEndpoint: '/game/start',
      statsGameEndpoint: '/stats',
    },
  },
  // Property disables interface if true and showing
  // special stub (popup), while node is updating for ex.
  unavailable: false,
  // Links for Chrome, Firefox extensions
  extensions: {
    chrome: 'https://chrome.google.com/webstore/detail/waves-keeper/lpilbniiabackdjcionkobglmddfbcjo',
    firefox: 'https://addons.mozilla.org/firefox/addon/waves-keeper',
    opera: 'https://addons.opera.com/en/extensions/details/waves-keeper',
  },
  dapp: {
    address: process.env.DAPP_ADDRESS || process.env.VUE_APP_DAPP_ADDRESS,
    publicKey: 'HcUXEkA9JTGp78LcTKsu4FmEpbDmnqntL4aMNLx1BHMB',
  },
  // Lifecycle tick updating leaderboards, game history
  lifecycleUpdateFrequency: 3000,
  networkCosts: 0.01,
  wave: 100000000,
  fees: {
    regular: 0.001,
    smartAccount: 0.005,
    startGameFee: 0.018,
  },
};
