<template>
  <div
    class="select"
    v-click-outside="onClickOutside"
    @click="toggleIsOpen"
  >
    {{ selectedLabel }}

    <select-chevron
      :class="[isOpen ? 'icon_rotate' : '']"
    ></select-chevron>

    <div
      :class="['select__options', isOpen ? 'select__options_visible' : '']"
    >
      <div
        v-for="(option, key) in options"
        :key="`${key}__${option[label]}`"
        :class="['select__option', selectedOption[valueProp] === option[valueProp] ? 'select__option_selected' : '']"
        @click="() => onSelect(option)"
      >
        {{ option[label] }}
      </div>
    </div>

  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import SelectChevron from './icons/SelectChevron.svg';

export default {
  directives: {
    ClickOutside,
  },
  components: {
    SelectChevron,
  },
  props: {
    options: Array,
    label: {
      type: String,
      default: 'title'
    },
    value: String,
    valueProp: {
      type: String,
      default: 'id'
    },
    disabled: Boolean,
  },
  computed: {
    selectedLabel() {
      return this.selectedOption?.[this.label] ?? '';
    },
    selectedOption() {
      return this.options.find((option) => option[this.valueProp] === this.value);
    },
    isSelected() {
      const { options, label, value } = this.$props;
      return !!options.find((option) => option[label] === value);
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    onSelect(event) {
      const selectedItem = this.options.find(option => option[this.valueProp] === event[this.valueProp]);
      if(selectedItem) {
        this.$emit('onselected', selectedItem);
      }
    }
  }
}
</script>

<style>
.select {
  min-width: 130px;
  height: 26px;
  column-gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: white;
  border-radius: 6px;
  background: linear-gradient(180deg, #6080D2 0%, #153891 100%), linear-gradient(180deg, #FFFFFF 0%, rgba(4, 26, 71, 0.94) 99.48%);
  box-shadow: 0px -4px 8px 1px #839CDFB0;
  cursor: pointer;
  padding: 0 12px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.select__options {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  background: rgb(96,128,210);
  background: linear-gradient(180deg, rgba(96,128,210,1) 0%, rgba(21,56,144,1) 100%);
  padding: 6px 12px;
  border-radius: 6px;
  z-index: 100;
  transition: opacity 0.3s;
}

.select__options_visible {
  visibility: visible;
  opacity: 1;
}


.select__option_selected {
  display: none;
}

.icon_rotate {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}
</style>