<template>
  <div class="mobile mobile__back" :class="[isMenuOpen || !viewState.main ? 'open' : '']">
    <div class="header" :class="[!isMenuOpen ? 'close' : '']">
      <button v-show="!isMenuOpen" class="header__button" v-on:click="changeRoute()">
        <OpenMobile />
      </button>
      <button v-show="isMenuOpen" class="header__button" v-on:click="changeRoute()">
        <CloseMobile />
      </button>
      <span class="menu__name menu__name--logo" v-if="viewState.main && !isMenuOpen">
        <img src="./assets/images/logo.png">
      </span>
      <span class="menu__name" v-if="viewState.rules && !isMenuOpen">
        {{$t('message.information.title')}}
      </span>
      <span class="menu__name" v-if="viewState.history && !isMenuOpen">
        {{$t('message.lastGames.title')}}
      </span>
      <control-change-language
        v-if="viewState.main && !isMenuOpen"
      ></control-change-language>

      <div class="log__main" v-if="isMenuOpen">
        <connect-or-action>
          <player-address></player-address>
        </connect-or-action>
      </div>

    </div>
    <mobile-menu
      v-if="isMenuOpen"
      class="mobile__menu">
    </mobile-menu>
    <mobile-interface
      v-if="viewState.main && !isMenuOpen"
      class="mobile__main">
    </mobile-interface>
    <mobile-rules
      v-if="viewState.rules && !isMenuOpen"
      class="mobile__rules">
    </mobile-rules>
    <mobile-history
      v-if="viewState.history && !isMenuOpen"
      class="mobile__history">
    </mobile-history>
    <popup-information></popup-information>
    <popup-insufficient-funds></popup-insufficient-funds>
    <popup-insufficient-funds-on-server></popup-insufficient-funds-on-server>
    <popup-game-info-selected></popup-game-info-selected>
    <popup-connect-wallet></popup-connect-wallet>
    <div :class="[!isLoaded ? 'active' : '']" class="preload">
      <p class="preload__text">
        {{ $t('message.loading') }}
      </p>
    </div>
    <transition name="fade">
      <scene-tutorial
        v-if="isTutorialActive"
      >
      </scene-tutorial>
    </transition>
  </div>
</template>

<script>
import MobileInterface from '@/components/mobile/Interface.vue';
import MobileRules from '@/components/mobile/Rules.vue';
import MobileMenu from '@/components/mobile/Menu.vue';
import ControlChangeLanguage from '@/components/mobile/ChangeLanguage.vue';
import MobileHistory from '@/components/mobile/History.vue';
import PopupInformation from '@/components/popups/PopupInformation.vue';
import PopupGameInfoSelected from '@/components/popups/PopupGameInfoSelected.vue';
import PopupInsufficientFunds from '@/components/popups/PopupInsufficientFunds.vue';
import PopupInsufficientFundsOnServer from '@/components/popups/PopupInsufficientFundsOnServer.vue';
import PopupConnectWallet from '@/components/popups/PopupConnectWallet.vue';
import SceneTutorial from '@/components/mobile/Tutorial.vue';
import ConnectOrAction from '@/components/controls/ConnectOrAction.vue';
import PlayerAddress from '@/components/ui/Address.vue';

import { mapGetters, mapActions } from 'vuex';

import OpenMobile from './assets/images/OpenMobile.svg';
import CloseMobile from './assets/images/CloseMobile.svg';

export default {
  components: {
    PopupConnectWallet,
    ConnectOrAction,
    PlayerAddress,
    MobileInterface,
    MobileMenu,
    MobileRules,
    MobileHistory,
    PopupGameInfoSelected,
    PopupInformation,
    OpenMobile,
    CloseMobile,
    ControlChangeLanguage,
    SceneTutorial,
    PopupInsufficientFunds,
    PopupInsufficientFundsOnServer,
  },
  created() {
    this.setAppLoadingHandler();
  },
  mounted() {
    if (localStorage.tutorial === 'undefined' || localStorage.tutorial === true) {
      this.setTutorial();
    }
  },
  methods: {
    ...mapActions({
      setTutorial: 'setTutorial',
      setAppLoadingHandler: 'setLoadingHandler',
      setMenuState: 'setMenuState',
      setLogIn: 'logIn',
      setLogOut: 'logOut',
    }),
    changeRoute() {
      this.setMenuState({ state: !this.isMenuOpen });
    },
    logIn() {
      this.setLogIn();
    },
    logOut() {
      this.setLogOut();
    },
  },
  computed: {
    ...mapGetters({
      statsLastGames: 'statsCurrentLastGames',
      isMenuOpen: 'isMenuOpen',
      isTutorialActive: 'isTutorialActive',
      viewState: 'viewState',
      isLoaded: 'isLoaded',
      isAuthenticated: 'isAuthenticated',
      playerAddress: 'playerAddress',
    }),
    playerAddressView() {
      return `${this.playerAddress.slice(0, 5)}***${this.playerAddress.slice(this.playerAddress.length - 5, this.playerAddress.length)}`;
    },
  },
};
</script>

<style lang="scss">

  html {
    &.mobile {
      body {
        overflow-y: auto;
        background-image: url("./assets/images/bg-mobile.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;
        max-height: calc(100vh - 70px);
        min-height: calc(100vh - 70px);
        &.menu {
          #Smallchat {
            opacity: 1 !important;
          }
        }
        #Smallchat {
          opacity: 0 !important;
        }
      }
    }
  }

  .menu {
    &__name {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 12px;
      &--logo {
        img {
          height: 50px;
          margin: 10px 0px 0px 0px;
        }
      }
    }
  }

  .mobile {
    &__back {
      display: flex;
      padding: 50px 0px 0px 0px;
      max-width: 100vw;
    }

    .mobile__button {
      .text {
        font-size: 12px;
        padding: 3px 3px 14px 3px;
      }
    }

    &.open {
      &:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.75);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__main, &__rules {
      width: 100%;
      z-index: 10;
    }

    &__main {
      padding: 5vh 0px 0px 0px;
    }

    &__history {
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    &__menu {
      position: absolute;
      left: 0;
      width: calc(100vw - 30px);
      padding: 0px 15px 0px 15px;
      color: #fff;
    }

    .header {
      height: 50px;
      color: #fff;
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: calc(100% - 10px);
      z-index: 101 !important;
      padding: 0px 5px;

      &.close {
        z-index: unset;
      }

      &__button {
        background: transparent;
        padding: 10px;
        border: 0px;
        line-height: 0px;
        outline: none;
      }
    }

    .text {
      text-transform: uppercase;
      text-align: center;

      &.message {
        width: 80%;

        .title {
          font-size: 5vw;
        }

        .content {
          font-size: 3vw;
        }
      }
    }

    .links {
      display: flex;
      flex-direction: row;

      a {
        font-size: 2vh;
        margin: 0 2vw;
      }
    }
  }
  .log {
    &__button {
      background-image: url('./assets/images/button-big-sprite.png');
      background-position: 183% 40%;
      background-size: 230%;
      background-color: transparent;
      color: #fff;
      border: 0px;
      padding: 14px;
      margin-left: 5px;
      width: 100px;
      height: 50px;
      margin-right: -10px;
    }
    &__main {
      text-align: right;
      margin-left: auto;

      > * {
        margin-left: auto;
      }
    }
  }
  .preload {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 10000000000000;
    color: #fff;
    display: none;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    &.active {
      display: block;
      opacity: 1;
    }
    &__text {
      margin: 0px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
