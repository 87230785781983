<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <scene-loading v-show="!isLoaded"></scene-loading>
    </transition>
    <transition name="fade" mode="in-out">
      <scene
        :class="{transparent: isTutorialActive}"
        v-show="isLoaded">
      </scene>
    </transition>
    <transition name="fade">
      <scene-tutorial
        v-if="isTutorialActive"
      >
      </scene-tutorial>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SceneLoading from '@/components/Loading.vue';
import SceneTutorial from '@/components/controls/Tutorial.vue';
import Scene from '@/components/Scene.vue';
import '@/assets/styles/index.scss';

export default {
  components: {
    Scene,
    SceneLoading,
    SceneTutorial,
  },
  computed: {
    ...mapGetters({
      isTutorialActive: 'isTutorialActive',
      isLoaded: 'isLoaded',
    }),
  },
  methods: {
    ...mapActions({
      setAppLoadingHandler: 'setLoadingHandler',
    }),
  },
  created() {
    this.setAppLoadingHandler();
  },
};
</script>

<style lang="scss">
  .transparent {
    pointer-events: none;
  }
</style>
