import * as Actions from './actions-types';
import * as Mutations from './mutation-types';
import * as Api from '@/api';
import config from '@/config';
import { Signer } from '@waves/signer';
import { BigNumber } from '@waves/bignumber';
import { ProviderWeb } from '@waves.exchange/provider-web';
import { ProviderCloud } from '@waves.exchange/provider-cloud';
import { ProviderKeeper } from '@waves/provider-keeper';

import {
  defaultGame,
  wait,
  extractResultFromString,
  Popups,
  States,
  ProcessSteps,
  assetsRecordById,
} from '@/utils';
import { first } from 'ts-liba';

let mode = null;
let signer = null;

const login = (loginMode) => {
  mode = loginMode;

  let provider = mode === 'seed'
    ? new ProviderWeb(config.api.providerWebIframeUrl)
    : new ProviderCloud();

  if(mode === 'keeper') {
    provider = new ProviderKeeper();
  }

  signer = new Signer({
    NODE_URL: config.api.node.url,
    LOG_LEVEL: 'verbose',
  });

  signer.setProvider(provider)

  return signer.login();
};

const invoke = ({
  dApp, chainId, call, payment, fee, feeAssetId,
}) => {
  return signer.invoke({
    dApp,
    chainId: chainId.charCodeAt(0),
    call,
    payment,
    feeAssetId,
    fee,
  }).broadcast();
};

const resolveFee = async (getters) => {
  const {
    betAmount,
    betAssetId,
  } = getters;

  const asset = assetsRecordById[getters.betAssetId];

  if (betAssetId === 'WAVES') {
    const wavesFee = 500000;
    const paymentBn = new BigNumber(betAmount).mul(new BigNumber(10).pow(asset.decimals))
      // .add(wavesFee);
    const payment = {
      amount: paymentBn.toString(),
      assetId: betAssetId,
    };
    return {
      feeAssetId: undefined,
      fee: wavesFee,
      payment,
      total: paymentBn.add(wavesFee).toNumber(),
    };
  }


  const sponsoredBalances = await signer.getSponsoredBalances();
  const sponsored = sponsoredBalances.find(x => x.assetId === betAssetId);
  //25FEqEjRkqK6yCkiT7Lz6SAYz7gUFCtxfCChnrVFD5AT

  if (!sponsored || !sponsored.sponsorship) {
    return undefined;
  }

  const feeBn = new BigNumber(sponsored.sponsorship).mul(5);
  const fee = feeBn.toNumber();
  const paymentBn = new BigNumber(betAmount).mul(new BigNumber(10).pow(asset.decimals)).add(fee);
  const payment = {
    amount: paymentBn.toString(),
    assetId: betAssetId,
  };

  return {
    feeAssetId: betAssetId, fee, payment, total: feeBn.add(paymentBn).toNumber(),
  };
};

let NEED_PLAY = false;

export default {
  logIn({ commit, dispatch }, playerLoginMethod) {
    // dispatch(Actions.AUTHENTICATE);

    const apply = ({ address, publicKey }) => {
      commit(Mutations.SET_PLAYER_ADDRESS, address);
      commit(Mutations.SET_PLAYER_PUBLICKEY, publicKey);
      commit(Mutations.SET_PLAYER_LOGIN_METHOD, playerLoginMethod);
      if (NEED_PLAY) {
        NEED_PLAY = false;
        dispatch('play');
      }
    };
    return login(playerLoginMethod).then(apply);
  },
  closeCurrentGame({ commit }) {
    commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
  },
  logOut({ dispatch }) {
    dispatch(Actions.LOGOUT);
  },
  setMenuState({ commit }, payload) {
    commit(Mutations.SET_MENU_STATE, payload.state);
    if (payload.pageView) {
      commit(Mutations.SET_CURRENT_VIEW, payload.pageView);
    }
  },
  setLastGamesOnlyMe({ commit, dispatch }, payload) {
    commit(Mutations.SET_LAST_GAMES_ONLY_ME, payload);
    dispatch(Actions.LAST_GAMES_UPDATE);
  },
  setTutorial({ dispatch, getters }, payload) {
    if (getters.isAnyPopupOpened) {
      return;
    }
    localStorage.setItem('tutorial', payload);
    dispatch(Actions.PROCEED_TUTORIAL);
  },
  setLoadingHandler({ commit, dispatch }) {
    window.addEventListener('load', () => {
      commit(Mutations.SET_LOADING_FINISHED);
      commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
      commit(Mutations.SET_NEW_GAME, defaultGame());
      dispatch(Actions.INITIALIZE);
    });
  },
  setPopupVisibility({ commit, getters }, { popup, visible }) {
    if (getters.isAnyPopupOpened && visible) {
      return;
    }

    if (getters.isUnavailable && !visible && Popups.INFORMATION === popup) {
      commit(Mutations.SET_POPUP_VISIBILITY, {
        popup: Popups.UNAVAILABLE,
        visible: true,
      });
      commit(Mutations.SET_POPUP_VISIBILITY, {
        popup: Popups.INFORMATION,
        visible: false,
      });
      return;
    }
    commit(Mutations.SET_POPUP_VISIBILITY, {
      popup,
      visible,
    });
  },
  setGameInformation({ commit }, payload) {
    commit(Mutations.SET_GAME_INFO_SELECTED, payload);
  },
  setBetAssetId({ commit }, payload) {
    commit(Mutations.SET_BET_ASSET_ID, payload);
  },
  setBetType({ getters, commit }, payload) {
    commit(Mutations.SET_BET_TYPE, payload);
    if (getters.choicesDone) {
      commit(Mutations.SET_BACKGROUND_FADED, false);
    }
  },
  setBetMultiplier({ getters, commit }, payload) {
    commit(Mutations.SET_BET_MULTIPLIER, payload);
    if (getters.choicesDone) {
      commit(Mutations.SET_BACKGROUND_FADED, false);
    }
  },
  setBackgroundFaded({ commit }, payload) {
    commit(Mutations.SET_BACKGROUND_FADED, payload);
  },
  async requestStatisticsUpdate({ commit }) {
    commit(Mutations.SET_LOADING_STATISTICS, true);

    const [
      statsLeaderboardCurrent,
      statsLeaderboardGeneral,
      statsTotalCurrent,
      statsTotalGeneral,
    ] = await Promise.all([
      Api.Backend.getStatsLeaderboard(config.api.gameId),
      Api.Backend.getStatsLeaderboard(),
      Api.Backend.getStatsTotal(config.api.gameId),
      Api.Backend.getStatsTotal(),
    ]);

    commit(Mutations.SET_STATS_CURRENT_LEADERBOARD, statsLeaderboardCurrent);
    commit(Mutations.SET_STATS_GENERAL_LEADERBOARD, statsLeaderboardGeneral);
    commit(Mutations.SET_STATS_CURRENT_TOTAL, statsTotalCurrent);
    commit(Mutations.SET_STATS_GENERAL_TOTAL, statsTotalGeneral);

    commit(Mutations.SET_LOADING_STATISTICS, false);
  },
  async play({ commit, getters, dispatch }) {
    commit(Mutations.SET_DISABLE_PLAY_BUTTON, true);
    // Process the game:
    try {
      let balance = 0;
      if (!getters.playerAddress) {
        signer
          .login()
          .then((response) => {
            // Move game to the checks state:
            commit(Mutations.SET_PLAYER_ADDRESS, response.address);
            commit(Mutations.SET_PLAYER_PUBLICKEY, response.publicKey);
            commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.CHECKS);

            signer.getBalance()
              .then(async (balanceResponse) => {
                balanceResponse.forEach((i) => {
                  if (i.assetId === getters.betAssetId) {
                    balance = parseInt(i.amount, 10);
                  }
                });

                const result = await resolveFee(getters);
                if (!result || result.total > balance) {
                  commit(Mutations.SET_POPUP_VISIBILITY, {
                    popup: Popups.INSUFFICIENT_FUNDS,
                    visible: true,
                  });
                  commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
                  commit(Mutations.SET_DISABLE_PLAY_BUTTON, false);
                  return;
                }
                // All checks passed, move to the processing:
                dispatch(Actions.PLAY_PROCESSING);
              });
          });
      } else {
        signer.getBalance()
          .then(async (balanceResponse) => {
            balanceResponse.forEach((i) => {
              if (i.assetId === getters.betAssetId) {
                balance = parseInt(i.amount, 10);
              }
            });

            const result = await resolveFee(getters);
            if (!result || result.total > balance) {
              commit(Mutations.SET_POPUP_VISIBILITY, {
                popup: Popups.INSUFFICIENT_FUNDS,
                visible: true,
              });
              commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
              commit(Mutations.SET_DISABLE_PLAY_BUTTON, false);
              return;
            }
            // All checks passed, move to the processing:
            dispatch(Actions.PLAY_PROCESSING);
          });
      }
    } finally {
      commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
      commit(Mutations.SET_NEW_GAME, defaultGame());
    }
  },
  async [Actions.INITIALIZE]({ dispatch }) {
    dispatch(Actions.PROCEED_TUTORIAL);
    dispatch(Actions.LIFECYCLE);
  },
  async [Actions.PROCEED_TUTORIAL]({ commit, getters }) {
    const active = localStorage.getItem('tutorial');
    if (active) {
      const status = JSON.parse(active);
      commit(Mutations.SET_TUTORIAL_ACTIVE, status);
      if (!status && getters.isUnavailable) {
        commit(Mutations.SET_POPUP_VISIBILITY, {
          popup: Popups.UNAVAILABLE,
          visible: true,
        });
      }
    } else {
      commit(Mutations.SET_TUTORIAL_ACTIVE, true);
    }
  },
  async [Actions.AUTHENTICATE]({ commit }) {
    signer
      .login()
      .then((response) => {
        commit(Mutations.SET_PLAYER_ADDRESS, response.address);
        commit(Mutations.SET_PLAYER_PUBLICKEY, response.publicKey);
      },
        (error) => {
          console.log(error);
        });
  },
  async [Actions.LOGOUT]({ commit }) {
    signer
      .logout()
      .then(() => {
        commit(Mutations.SET_PLAYER_ADDRESS, null);
        commit(Mutations.SET_PLAYER_PUBLICKEY, null);
      },
        (error) => {
          console.log(error);
        });
  },
  async [Actions.LIFECYCLE]({ commit, dispatch, getters }) {
    const {
      lastGamesUpdatedAt,
      isUnavailable,
      isTutorialActive,
      openedPopup,
    } = getters;

    const difference = (new Date().getTime() - lastGamesUpdatedAt);
    if (difference < config.lifecycleUpdateFrequency) {
      await wait(config.lifecycleUpdateFrequency - difference);
    } else {
      await wait(config.lifecycleUpdateFrequency);
    }

    try {
      await dispatch(Actions.LAST_GAMES_UPDATE);
      if (isUnavailable) {
        commit(Mutations.SET_GAME_UNAVAILABLE, false);
        commit(Mutations.SET_POPUP_VISIBILITY, {
          popup: Popups.UNAVAILABLE,
          visible: false,
        });
      }
    } catch (e) {
      console.log(e);

      if (!isUnavailable) {
        if (openedPopup) {
          commit(Mutations.SET_POPUP_VISIBILITY, {
            popup: Popups[getters.openedPopup],
            visible: false,
          });
        }
        commit(Mutations.SET_GAME_UNAVAILABLE, true);
        if (!isTutorialActive) {
          commit(Mutations.SET_POPUP_VISIBILITY, {
            popup: Popups.UNAVAILABLE,
            visible: true,
          });
        }
      }
    } finally {
      dispatch(Actions.LIFECYCLE);
    }
  },
  async [Actions.LAST_GAMES_UPDATE]({ commit, getters }) {
    const { lastGamesOnlyMe, playerAddress } = getters;
    if (lastGamesOnlyMe) {
      const statsLastGames = (await Api.Backend.getStatsLastGamesByAddress(
        config.api.gameId,
        playerAddress,
      )).map(x => ({ ...x, betAssetId: parseInt(x.betAssetId, 10) }));
      if (getters.lastGamesOnlyMe) {
        commit(Mutations.SET_STATS_CURRENT_LAST_GAMES, statsLastGames);
      }
    } else {
      const statsLastGames = (await Api.Backend.getStatsLastGames(config.api.gameId))
        .map(x => ({ ...x, betAssetId: parseInt(x.betAssetId, 10) }));
      if (!getters.lastGamesOnlyMe) {
        commit(Mutations.SET_STATS_CURRENT_LAST_GAMES, statsLastGames);
      }
    }
    commit(Mutations.SET_LAST_GAMES_UPDATE_AT, new Date().getTime());
  },
  async [Actions.PLAY_PROCESSING]({ getters, commit }) {
    try {
      const {
        gameInfoCurrent: {
          coinSide,
          betAmount,
          betAssetId,
        },
      } = getters;
      const asset = assetsRecordById[betAssetId];

      const func = 'bet';
      const argsArr = [
        {
          type: 'string',
          value: coinSide.toString(),
        },
      ];

      const result = await resolveFee(getters);
      if (!result) {
        commit(Mutations.SET_POPUP_VISIBILITY, {
          popup: Popups.INSUFFICIENT_FUNDS,
          visible: true,
        });
        return;
      }

      const { payment, feeAssetId, fee } = result;

      const tx = {
        dApp: config.dapp.address,
        chainId: config.api.network,
        call: {
          function: func,
          args: argsArr,
        },
        payment: [
          payment,
          {
            amount: 500000,
            assetId: 'WAVES',
          }
        ],
        feeAssetId,
        fee,
      };
      let responseGameId;

      try {
        await invoke(tx)
          .then((response) => {
            let resp = response;
            if(Array.isArray(resp)) {
              resp = first(response);
            }
            responseGameId = resp.id;
            commit(Mutations.SET_CURRENT_GAME_ID, responseGameId);
          })
          .catch((err) => {
            if(err?.message?.includes('Bet amount is not valid')) {
              commit(Mutations.SET_POPUP_VISIBILITY, {
                popup: Popups.SERVER_INSUFFICIENT_FUNDS,
                visible: true,
              });
            }
            commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
            commit(Mutations.SET_DISABLE_PLAY_BUTTON, false);
            throw new Error(err.message);
          })


        // await signer
        //   .invoke(tx)
        //   .broadcast()
        //   .then((response) => {
        //     const resp = first(response);
        //     responseGameId = resp.id;
        //     commit(Mutations.SET_CURRENT_GAME_ID, responseGameId);
        //   });
      } catch (e) {
        if (e.error === 112) {
          commit(Mutations.SET_POPUP_VISIBILITY, {
            popup: Popups.INSUFFICIENT_FUNDS,
            visible: true,
          });
        }
        if (e.error === 306) {
          commit(Mutations.SET_POPUP_VISIBILITY, {
            popup: Popups.SERVER_INSUFFICIENT_FUNDS,
            visible: true,
          });
        }
        commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
        return;
      } finally {
        commit(Mutations.SET_DISABLE_PLAY_BUTTON, false);
      }

      // Starts to poll backend for the game result:
      commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.WAIT_WINNER);
      let gameWinner = null;
      do {
        try {
          // Wait one second before poll:
          // eslint-disable-next-line
          await wait(1000);
          // Get game info for current state of the match.
          // Exit from cycle if winner is defined.
          // eslint-disable-next-line
          const resp = await Api.Node.getGameResultById(responseGameId);
          if (!resp.error) {
            const value = extractResultFromString(resp.value);
            if (value === States.WON || value === States.LOST) {
              gameWinner = value;
            }
          }
        } catch (e) {
          // ignore error
        }
      } while (!gameWinner && !getters.isUnavailable);

      // Define winner:
      commit(Mutations.SET_WINNER, gameWinner);

      // Show the game result for user for 3 seconds:
      await wait(3000);

      commit(Mutations.SET_PROCESSING_STEP, ProcessSteps.IDLE);
    } catch (e) {
      throw e;
    }
  },
};
