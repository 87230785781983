<template>
  <div class="only-me">
    <input type="checkbox" id="control" name="control" v-model="checked">
    <label for="control">{{$t('message.lastGames.onlyMe')}}</label>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      lastGamesOnlyMe: 'lastGamesOnlyMe',
    }),
    checked: {
      set(v) {
        this.setLastGamesOnlyMe(v);
      },
      get() {
        return this.lastGamesOnlyMe;
      },
    },
  },
  methods: {
    ...mapActions({
      setLastGamesOnlyMe: 'setLastGamesOnlyMe',
    }),
  },
};
</script>

<style scoped lang="scss">
  .only-me {
    opacity: 0.8;
    position: absolute;
    left: 0;
    padding: 0 0.5vw;
    background-color: rgba(6, 29, 71, 0.8);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 0.3vw;
    display: inline-flex;
    justify-content: center;
    align-items: baseline;

    @media (min-aspect-ratio: 16/9) {
      padding: 0 calc((16 / 9) * 0.5vh);
      border-radius: calc((16 / 9) * 0.3vh);
    }

    label {
      font-family: Roboto, sans-serif;
      font-style: italic;
      font-weight: bold;
      font-size: 0.7vw;
      text-transform: none;
      padding: 0.3vw;
      margin: 0;

      @media (min-aspect-ratio: 16/9) {
        font-size: calc((16 / 9) * 0.7vh);
        padding: calc((16 / 9) * 0.3vh);
      }
    }

    input[type=checkbox] {
      @media (min-aspect-ratio: 16/9) {
        transform: scale(calc((9 / 16) * 2), calc((9 / 16) * 2));
      }

      @media (min-aspect-ratio: 9/16) {
        transform: scale(calc((9 / 16) * 2), calc((9 / 16) * 2));
      }
    }
  }
</style>
