<template>
  <div class="skip__block">
    <div class="skip__item">
      <div @click="skip" class="skip__button">{{$t('message.tutorial.skip')}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Driver from 'driver.js';

const options = {
  className: 'tutorial-popover',
  animate: true,
  opacity: 0.75,
  padding: 10,
  showButtons: false,
  allowClose: false,
  overlayClickNext: false,
  stageBackground: 'transparent',
};

export default {
  data() {
    return {
      driver: new Driver(options),
      stepsDone: 0,
      steps: [
        {
          element: '#tutorial-step-1',
          popover: {
            title: this.$t('message.tutorial.step-1.title'),
            position: 'bottom-center',
          },
        },
        {
          element: '#tutorial-step-2',
          popover: {
            title: this.$t('message.tutorial.step-2.title'),
            position: 'bottom-center',
          },
        },
        {
          element: '#tutorial-step-3',
          popover: {
            title: this.$t('message.tutorial.step-3.title'),
            position: 'top-center',
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isTutorialActive: 'isTutorialActive',
    }),
  },
  methods: {
    ...mapActions({
      setTutorial: 'setTutorial',
    }),
    nextStep() {
      this.stepsDone += 1;
      const step = this.steps.shift();
      if (step) {
        this.driver.highlight(step);
      } else {
        this.closeTutorial();
      }
    },
    skip(event) {
      if (event.key === 'Escape' || event.type === 'click') {
        this.closeTutorial();
      }
    },
    closeTutorial() {
      window.removeEventListener('click', this.nextStep, true);
      window.removeEventListener('keydown', this.skip, false);
      this.driver.reset(true);
      this.setTutorial(false);
    },
  },
  mounted() {
    this.nextStep();
    window.addEventListener('click', this.nextStep, true);
    window.addEventListener('keydown', this.skip, false);
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1024px) {
  .skip {
    &__button {
      display: inline-flex;
      color: white;
      z-index: 100000000000;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      position: absolute;
      padding: 5px 20px;
      right: 50%;
      transform: translate(50%, 0);
      background-color: #1c6aff;
      border-radius: 10px;
      opacity: 0.9;
      top: 40px;

      &:hover {
        cursor: pointer;
        background-color: #3091ff;
      }
    }
  }

  .help {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 200px;
    height: auto;
  }

  div#driver-popover-item {
    text-align: center;
    max-width: 250px;
    left: calc(((100vw - 30px) - 250px) / 2) !important;

    .driver-popover-title {
      font: 14px/normal sans-serif;
    }
  }
}

</style>
