/* eslint-disable */
import { Stages } from '@/models';
import actions from './actions';
import config from '@/config';
import * as Mutations from './mutation-types';
import {
  ProcessSteps,
  Popups,
  assetsRecordById,
} from '@/utils';

const state = {
  stage: Stages.Initial,
  wavesKeeperInitialized: false,
  loaded: false,
  notification: null,
  backgroundFaded: false,
  loadingProgress: 0,
  loadingStatistics: false,
  playerNetwork: null,
  playerAddress: null,
  playerPublicKey: null,
  processingStep: ProcessSteps.IDLE,
  unavailable: false,
  isPlayButtonDisabled: false,
  viewState: {
    main: true,
    liderBoard: false,
    history: false,
    rules: false,
  },
  menuOpen: false,
  popupsVisibility: {
    [Popups.UNAVAILABLE]: config.unavailable || false,
    [Popups.INSUFFICIENT_FUNDS]: false,
    [Popups.SERVER_INSUFFICIENT_FUNDS]: false,
    [Popups.ADD_ACCOUNT]: false,
    [Popups.PERMISSIONS]: false,
    [Popups.LEADERBOARD]: false,
    [Popups.INFORMATION]: false,
    [Popups.GAME_INFORMATION]: false,
    [Popups.SWITCH_NETWORK]: false,
    [Popups.LOOSE]: false,
    [Popups.WIN]: false,
    [Popups.CONNECT_WALLET]: false,
  },
  gameInfoSelected: null,
  gameInfoCurrent: {
    gameId: null,
    coinSide: Math.random() > 0.5 ? 0 : 1,
    winner: null,
    betAmount: 2,
    betAssetId: 'WAVES',
  },
  stats: {
    current: {
      total: {
        totalPlayers: 0,
        totalCleanWonAmount: 0,
        timesPlayed: 0,
      },
      leaderboard: [],
      lastGames: [],
    },
    general: {
      total: {
        totalPlayers: 0,
        totalCleanWonAmount: 0,
        timesPlayed: 0,
      },
      leaderboard: [],
      lastGames: [],
    },
  },
  lastGamesUpdatedAt: 0,
  lastGamesOnlyMe: false,
  tutorialActive: false,
};

const mutations = {
  [Mutations.SET_DISABLE_PLAY_BUTTON](state, disable) {
    state.isPlayButtonDisabled = disable;
  },
  [Mutations.SET_MENU_STATE](state, payload) {
    state.menuOpen = payload;
  },
  [Mutations.SET_BET_ASSET_ID](state, payload) {
    state.gameInfoCurrent.betAssetId = payload;
  },
  [Mutations.SET_CURRENT_VIEW](state, payload) {
    for (let key in state.viewState) {
      state.viewState[key] = false;
    }
    state.viewState[payload] = true;
  },
  [Mutations.SET_TUTORIAL_ACTIVE](state, payload) {
    state.tutorialActive = payload;
  },
  [Mutations.SET_PLAYER_LOGIN_METHOD](state, loginMethod) {
    state.loginMethod = loginMethod;
  },
  [Mutations.SET_NEW_GAME](state, payload) {
    const {
      gameId,
      winner,
    } = payload;
    state.gameInfoCurrent.gameId = gameId;
    state.gameInfoCurrent.winner = winner;
  },
  [Mutations.SET_LOADING_FINISHED](state) {
    state.loaded = true;
  },
  [Mutations.SET_LOADING_STATISTICS](state, payload) {
    state.loadingStatistics = payload;
  },
  [Mutations.SET_PROCESSING_STEP](state, payload) {
    state.processingStep = payload;
  },
  [Mutations.SET_CURRENT_GAME_ID](state, payload) {
    state.gameInfoCurrent.gameId = payload;
  },
  [Mutations.SET_BET_TYPE](state, betType) {
    state.gameInfoCurrent.coinSide = betType;
  },
  [Mutations.SET_BET_MULTIPLIER](state, betMultiplier) {
    state.gameInfoCurrent.betAmount = betMultiplier;
  },
  [Mutations.SET_GAME_STAGE](state, stage) {
    state.stage = stage;
  },
  [Mutations.SET_WAVES_KEEPER_INIT](state, initialized) {
    state.wavesKeeperInitialized = initialized;
  },
  [Mutations.SET_NOTIFICATION](state, notification) {
    state.notification = notification;
  },
  [Mutations.SET_BACKGROUND_FADED](state, faded) {
    state.backgroundFaded = faded;
  },
  [Mutations.SET_LOADING_PROGRESS](state, payload) {
    state.loadingProgress = payload;
  },
  [Mutations.SET_GAME_INFO_SELECTED](state, payload) {
    state.gameInfoSelected = payload;
  },
  [Mutations.SET_POPUP_VISIBILITY](state, { popup, visible }) {
    state.popupsVisibility[popup] = visible;
  },
  [Mutations.SET_PLAYER_NETWORK](state, payload) {
    state.playerNetwork = payload;
  },
  [Mutations.SET_PLAYER_ADDRESS](state, payload) {
    state.playerAddress = payload;
  },
  [Mutations.SET_PLAYER_PUBLICKEY](state, payload) {
    state.playerPublicKey = payload;
  },
  [Mutations.SET_STATS_CURRENT_LEADERBOARD](state, payload) {
    state.stats.current.leaderboard = payload;
  },
  [Mutations.SET_STATS_CURRENT_LAST_GAMES](state, payload) {
    state.stats.current.lastGames = payload;
  },
  [Mutations.SET_STATS_CURRENT_TOTAL](state, payload) {
    const {
      totalPlayers,
      totalCleanWonAmount,
      timesPlayed,
    } = payload;
    state.stats.current.total.totalPlayers = totalPlayers || 0;
    state.stats.current.total.totalCleanWonAmount = totalCleanWonAmount || 0;
    state.stats.current.total.timesPlayed = timesPlayed || 0;
  },
  [Mutations.SET_STATS_GENERAL_LEADERBOARD](state, payload) {
    state.stats.general.leaderboard = payload;
  },
  [Mutations.SET_STATS_GENERAL_TOTAL](state, payload) {
    const {
      totalPlayers,
      totalCleanWonAmount,
      timesPlayed,
    } = payload;
    state.stats.general.total.totalPlayers = totalPlayers || 0;
    state.stats.general.total.totalCleanWonAmount = totalCleanWonAmount || 0;
    state.stats.general.total.timesPlayed = timesPlayed || 0;
  },
  [Mutations.SET_WINNER](state, payload) {
    state.gameInfoCurrent.winner = payload;
  },
  [Mutations.SET_GAME_UNAVAILABLE](state, payload) {
    state.unavailable = payload;
  },
  [Mutations.SET_LAST_GAMES_ONLY_ME](state, payload) {
    state.lastGamesOnlyMe = payload;
  },
  [Mutations.SET_LAST_GAMES_UPDATE_AT](state, payload) {
    state.lastGamesUpdatedAt = payload;
  },
};

const getters = {
  viewState: state => state.viewState,
  isMenuOpen: state => state.menuOpen,
  betType: store => store.betType,
  betAssetId: state => state.gameInfoCurrent.betAssetId,
  betAssetName: state => assetsRecordById[state.gameInfoCurrent.betAssetId].name,
  betAssetTicker: state => assetsRecordById[state.gameInfoCurrent.betAssetId].ticker,
  betMultiplier: store => store.betMultiplier,
  choicesDone: store => store.betType != null && store.betMultiplier != null,
  isTutorialActive: state => state.tutorialActive,
  isLoaded: state => state.loaded,
  isAnyPopupOpened: state => Object.entries(state.popupsVisibility)
    .some(value => value[1] === true),
  isAuthenticated: state => state.playerAddress !== null,
  isProcessing: state => state.processingStep > ProcessSteps.IDLE,
  isPlayBtnDisabled: state => state.isPlayButtonDisabled,
  stage: store => store.stage,
  isUnavailable: state => state.unavailable,
  isUnavailablePopup: state => state.popupsVisibility[Popups.UNAVAILABLE],
  wavesKeeperInitialized: store => store.wavesKeeperInitialized,
  notification: store => store.notification,
  backgroundFaded: store => store.backgroundFaded,
  loadingProgress: state => state.loadingProgress,
  loadingStatistics: state => state.loadingStatistics,
  popupsVisibility: state => state.popupsVisibility,
  playerNetwork: state => state.playerNetwork,
  playerAddress: state => state.playerAddress,
  playerPublicKey: state => state.playerPublicKey,
  processingStep: state => state.processingStep,
  gameInfoSelected: state => state.gameInfoSelected,
  gameInfoCurrent: state => state.gameInfoCurrent,
  coinSide: state => state.gameInfoCurrent.coinSide,
  betAmount: state => state.gameInfoCurrent.betAmount,
  statsCurrentTotal: state => state.stats.current.total,
  statsCurrentLeaderboard: state => state.stats.current.leaderboard,
  statsCurrentLastGames: state => state.stats.current.lastGames,
  statsGeneralTotal: state => state.stats.general.total,
  statsGeneralLeaderboard: state => state.stats.general.leaderboard,
  lastGamesOnlyMe: state => state.lastGamesOnlyMe,
  lastGamesUpdatedAt: state => state.lastGamesUpdatedAt,
  winningAmount: state => state.gameInfoCurrent.betAmount * 1.9000,
  openedPopup: state => {
    for (let key in state.popupsVisibility) {
      if(state.popupsVisibility[key] === true) {
        return key
      }
    }
    return null;
  },
};

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
};

