<template>
  <div class="flex column">
    <div class="btn__block">
      <button class="btn__item" v-on:click="changeRoute('main')">
        {{$t('message.menu.play')}}
      </button>
      <!--
        <button class="btn__item" v-on:click="changeRoute('liderBoard')">
          Лидеры
        </button>
      -->
      <button class="btn__item" v-on:click="changeRoute('history')">
        {{$t('message.menu.history')}}
      </button>
      <button class="btn__item" v-on:click="changeRoute('rules')">
        {{$t('message.menu.rules')}}
      </button>
      <button class="btn__item" v-on:click="showTutorial()">
        {{$t('message.menu.howTo')}}
      </button>
      <!-- <control-socials></control-socials> -->
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
// import ControlSocials from '@/components/controls/Socials.vue';

export default {
  components: {
    // ControlSocials,
  },
  computed: {

  },
  beforeCreate() {
    document.querySelector('body').classList.add('menu');
  },
  beforeDestroy() {
    document.querySelector('body').classList.remove('menu');
  },
  methods: {
    ...mapActions({
      setMenuState: 'setMenuState',
      setTutorial: 'setTutorial',
    }),
    changeRoute(view) {
      this.setMenuState({ state: false, pageView: view });
    },
    showTutorial() {
      this.setTutorial(true);
      this.setMenuState({ state: false, pageView: 'main' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
    &__item {
      text-align: left;
      padding: 0px;
      color: #fff;
      width: 100%;
      margin-bottom: 30px;
      border: 0px;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      &:first-child {
        margin-top: 15px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
</style>
