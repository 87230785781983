export const SERVER_INSUFFICIENT_FUNDS = 'SERVER_INSUFFICIENT_FUNDS';
export const INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS';
export const PERMISSIONS = 'PERMISSIONS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const INFORMATION = 'INFORMATION';
export const GAME_INFORMATION = 'GAME_INFORMATION';
export const SWITCH_NETWORK = 'SWITCH_NETWORK';
export const LEADERBOARD = 'LEADERBOARD';
export const UNAVAILABLE = 'UNAVAILABLE';
export const LOOSE = 'LOOSE';
export const WIN = 'WIN';
export const CONNECT_WALLET = 'CONNECT_WALLET';
