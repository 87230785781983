<template>
  <div class="only-me">
    <div class="only-me__item">
      <button :class="[!checked ? 'active' : '']" class="only-me__btn" @click="checked = false">
        {{$t('message.mobile.lastGames.allGame')}}
      </button>
    </div>
    <div class="only-me__item">
      <button :class="[checked ? 'active' : '']" class="only-me__btn" @click="checked = true">
        {{$t('message.mobile.lastGames.onlyMe')}}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      lastGamesOnlyMe: 'lastGamesOnlyMe',
    }),
    checked: {
      set(v) {
        this.setLastGamesOnlyMe(v);
      },
      get() {
        return this.lastGamesOnlyMe;
      },
    },
  },
  methods: {
    ...mapActions({
      setLastGamesOnlyMe: 'setLastGamesOnlyMe',
    }),
  },
};
</script>

<style scoped lang="scss">
  .only-me {
    display: flex;
    padding: 0px 10px;
    &__item {
      padding: 0px;
      flex: 0 0 50%;
      max-width: 50%;
      text-align: center;
    }
    &__btn {
      margin: 0px;
      color: #fff;
      border: 0px;
      background: transparent;
      width: 100%;
      padding: 20px 0px;
      outline: none;
      &.active {
        background: url('../../assets/images/button-small-sprite.png') 0 1px no-repeat;
        background-size: 200% 100%;
      }
    }
  }
</style>
