const Waves = {
  signTransaction(d) {
    return this.provider.signTransaction(d);
  },
  publicState() {
    return this.provider.publicState();
  },
  install(api) {
    this.provider = api;
  },
  provider: null,

};

export default {
  eventUpdate: 'update',
  initialPromise: Promise.resolve({}),
  ...Waves,
};
