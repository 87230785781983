import * as Stages from './stages';

const BetType = {
  HEADS: 0,
  TAILS: 1,
};

const BetMultiplier = {
  X1: 1,
  X2: 2,
  X4: 4,
  X8: 8,
  X12: 14,
};

export { BetType, BetMultiplier, Stages };
