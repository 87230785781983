export const SET_TUTORIAL_ACTIVE = 'SET_TUTORIAL_ACTIVE';
export const SET_NEW_GAME = 'SET_NEW_GAME';
export const SET_CURRENT_GAME_ID = 'SET_CURRENT_GAME_ID';
export const SET_BET_TYPE = 'SET_BET_TYPE';
export const SET_BET_MULTIPLIER = 'SET_BET_MULTIPLIER';
export const SET_GAME_STAGE = 'SET_GAME_STAGE';
export const SET_WAVES_KEEPER_INIT = 'SET_WAVES_KEEPER_INIT';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_BACKGROUND_FADED = 'SET_BACKGROUND_FADED';
export const SET_LOADING_PROGRESS = 'SET_LOADING_PROGRESS';
export const SET_LOADING_STATISTICS = 'SET_LOADING_STATISTICS';
export const SET_POPUP_VISIBILITY = 'SET_POPUP_VISIBILITY';
export const SET_LOADING_FINISHED = 'SET_LOADING_FINISHED';
export const SET_WINNER = 'SET_WINNER';
export const SET_PROCESSING_STEP = 'SET_PROCESSING_STEP';
export const SET_STATS_CURRENT_LEADERBOARD = 'SET_STATS_CURRENT_LEADERBOARD';
export const SET_STATS_CURRENT_LAST_GAMES = 'SET_STATS_CURRENT_LAST_GAMES';
export const SET_STATS_CURRENT_TOTAL = 'SET_STATS_CURRENT_TOTAL';
export const SET_STATS_GENERAL_LEADERBOARD = 'SET_STATS_GENERAL_LEADERBOARD';
export const SET_STATS_GENERAL_TOTAL = 'SET_STATS_GENERAL_TOTAL';
export const SET_PLAYER_NETWORK = 'SET_PLAYER_NETWORK';
export const SET_PLAYER_ADDRESS = 'SET_PLAYER_ADDRESS';
export const SET_PLAYER_PUBLICKEY = 'SET_PLAYER_PUBLICKEY';
export const SET_GAME_INFO_SELECTED = 'SET_GAME_INFO_SELECTED';
export const SET_BET_ASSET_ID = 'SET_BET_ASSET_ID';
export const SET_GAME_UNAVAILABLE = 'SET_GAME_UNAVAILABLE';
export const SET_LAST_GAMES_ONLY_ME = 'SET_LAST_GAMES_ONLY_ME';
export const SET_LAST_GAMES_UPDATE_AT = 'SET_LAST_GAMES_UPDATE_AT';
export const SET_MENU_STATE = 'SET_MENU_STATE';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_PLAYER_LOGIN_METHOD = 'SET_PLAYER_LOGIN_METHOD';
export const SET_DISABLE_PLAY_BUTTON = 'SET_PLAYER_DISABLE_BUTTON';
