import Vue from 'vue';
import device from 'current-device';
import AppDesktop from './App.vue';
import AppMobile from './AppMobile.vue';
import store from './store';
import i18n from './i18n';

Vue.config.productionTip = false;

const App = device.desktop() ? AppDesktop : AppMobile;

new Vue({
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
