<template>
  <div :class="['button', backgroundVariant, {disabled: disabled}]" @click="onClick">
    <div class="flex text white align-items-center">{{title}}</div>
    <div class="flex text white align-items-center">
      {{label}}
      <component class="icon" :is="`icon${icon}`"></component>
    </div>
  </div>
</template>

<script>
  import iconKeeper from '@/assets/images/Keeper_Icon.svg';
  import iconEmail from '@/assets/images/email.svg';
  import iconSeed from '@/assets/images/seed.svg';

  export default {
    components: {
      iconKeeper,
      iconEmail,
      iconSeed,
    },
    props: {
      variant: {
        type: Number,
        default: 0,
      },
      title: String,
      label: String,
      icon: String,
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      backgroundVariant() {
        if (this.variant === 1) {
          return 'variant-1';
        }

        return 'variant-2';
      },
      computedClass() {
        return this.icon === 'Keeper' ? ['icon-scale'] : [];
      },
    },
    methods: {
      onClick() {
        this.$emit('click');
      },
    },
  };
</script>

<style scoped lang="scss">
  $button-height: 3;

  .mobile__connect-wallet {
    .button {
      padding: 0 14px !important;
      height: 60px !important;

      .text {
        padding: 22px 16px 18px 16px !important;
        font-size: 14px !important;
      }

      .icon {
        width: 20px !important;
        height: 20px !important;
        margin-left: 16px !important;
      }
    }
  }

  .button {
    width: 100%;
    height: #{$button-height}vw;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 0 calc((9 / 16) * 3vw);

    .icon {
      width: 20px !important;
      height: 20px !important;

      margin-left: calc((9 / 16) * 2vw);
    }

    &.variant-1 {
      background: url("../../assets/images/button-small-sprite.png") 0 0 no-repeat;
      background-size: 200% 150%;

      &:hover:not(.disabled) {
        background: url("../../assets/images/button-small-sprite.png") 100% 0 no-repeat;
        background-size: 200% 150%;
      }
    }

    &.variant-2 {
      background: url("../../assets/images/button-small-sprite-2.png") 0 0 no-repeat;
      background-size: 200% 150%;


      &:hover:not(.disabled) {
        background: url("../../assets/images/button-small-sprite-2.png") 100% 0 no-repeat;
        background-size: 200% 150%;
      }
    }

    @media (min-aspect-ratio: 16/9) {
      height: calc((16 / 9) * #{$button-height}vh) !important;
      padding: 0 calc((9 / 16) * 7vh);

      .text {
        padding: calc((9 / 16) * 1.9vh) calc((9 / 16) * 1.5vh);
        font-size: calc((16 / 9) * 0.8vh) !important;
      }

      .icon {
        width: calc((9 / 16) * 3.5vh);
        height: calc((9 / 16) * 3.5vh);
        margin-left: calc((9 / 16) * 3.5vh);
      }
    }

    .text {
      padding: calc((9 / 16) * 1.9vw) calc((9 / 16) * 1.5vw);
      font-size: 0.8vw;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 32px;
      padding: 0 24px;
      .text {
        font-size: 10px;
      }
    }
  }
</style>
