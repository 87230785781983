import { render, staticRenderFns } from "./Central.vue?vue&type=template&id=487b1bf6&scoped=true&"
import script from "./Central.vue?vue&type=script&lang=js&"
export * from "./Central.vue?vue&type=script&lang=js&"
import style0 from "./Central.vue?vue&type=style&index=0&id=487b1bf6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "487b1bf6",
  null
  
)

export default component.exports