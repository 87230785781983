/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import * as Api from '@/api';
import * as Popups from '@/utils/popups';
import * as Winners from '@/utils/winners';
import * as ProcessSteps from '@/utils/steps';
import * as States from '@/utils/states';
import thumbnails from './thumbnails';
import getAgentExtLink from '@/utils/agents';

export { assets, assetsRecordById, assetsRecordByContractId } from '@/utils/assets';

export const wait = ms => new Promise(r => setTimeout(r, ms));

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const waitTxUntilAccepted = async (id) => {
  // while tx is not assigned to the BC:
  let txInBlockchain = null;
  do {
    try {
      // case #1: exception here - ERR_CONNECTION, retry
      // case #2: null object - 404, tx not in BC, retry
      // eslint-disable-next-line
      txInBlockchain = await Api.Node.getTransactionById(id);
    } catch (e) {
      // ignore error
    }
    // eslint-disable-next-line
    await wait(1000);
  } while (!txInBlockchain);
};

export const broadcastTxUntilAccepted = async (tx) => {
  let txBroadcast = null;
  do {
    try {
      // case #1: exception here - ERR_CONNECTION, retry
      // case #2: null object - 404, tx is not allowed by account-script, retry
      // case #3: not null object, but contains error, retry
      // eslint-disable-next-line
      txBroadcast = await Api.Node.broadcastTx(tx);
      if (txBroadcast && txBroadcast.error) {
        txBroadcast = null;
      }
    } catch (e) {
      // ignore error
    }
    // eslint-disable-next-line
    await wait(1000);
  } while (!txBroadcast);

  return txBroadcast;
};

export const dicesToString = dicesArray => dicesArray.join('');

export const dicesFromString = (dicesString) => {
  const dices = [];
  for (let idx = 0; idx < dicesString.length; idx += 1) {
    const dice = parseInt(dicesString.charAt(idx), 10);
    if (!dices.includes(dice)) {
      dices.push(dice);
    }
  }

  return dices;
};

export const selectRandomDices = (dicesAmount) => {
  const getRandomFromRange = (min, max) => Math.floor(min + Math.random() * (max + 1 - min));
  const dices = [];
  while (dices.length < dicesAmount) {
    const value = getRandomFromRange(1, 6);
    if (!dices.includes(value)) {
      dices.push(value);
    }
  }
  return dices.sort();
};

export const addressTruncate = (address, fist, last, placeholder) => {
  if(!address) {
    return '';
  }

  return address.slice(0, fist)
    .concat(placeholder)
    .concat(address.slice(-last));

}

export const extractResultFromString = (str) => {
  const values = str.split('_');
  return values[0];
};

export const getElementAtOr = (array, index, or) => {
  if (typeof array[index] === 'undefined') {
    return or;
  }

  return array[index];
};

export const defaultGame = () => ({
  gameId: null,
  winner: null,
});

export const formatNumber = (number) => {
  const n = number >= 1000 ? Math.trunc(number) : number;
  return new Intl.NumberFormat().format(n);
};

export const invocationTransaction = (
  dApp,
  senderPublicKey,
  payment,
  func,
  args,
) => ({
  type: 16,
  data: {
    dApp,
    senderPublicKey,
    payment: [payment],
    call: {
      function: func,
      args,
    },
    fee: {
      assetId: 'WAVES',
      coins: 500000,
    },
  },
});

export { thumbnails };
export { Popups };
export { Winners };
export { States };
export { ProcessSteps };
export { getAgentExtLink };
