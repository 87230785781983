<template>
  <div>
    <div class="flex column justify-content-center align-items-center">
      <div id="tutorial-step-1">
        <scene-bet-type-container></scene-bet-type-container>
      </div>
      <div id="tutorial-step-2">
        <scene-bet-multiplier-container></scene-bet-multiplier-container>
      </div>
    </div>
    <div class="flex full stat__play">
      <div class="section section__3">
        <p class="section__text text white centered">
          {{$t('message.sections.winningChance')}}
        </p>
        <div class="section__block">
          <p class="section__value yellow glow value text shadow centered">
            50.00%
          </p>
        </div>
      </div>
      <div class="section section__3">
        <p class="section__text text white centered">
          {{$t('message.sections.winningCoefficient')}}
        </p>
        <div class="section__block">
          <p class="section__value text centred yellow glow value">
            <span class="x-pointer">x</span>1.90
          </p>
        </div>
      </div>
      <div class="section section__3">
        <p class="section__text text white centered">
          {{$t('message.sections.youMayWin')}}
        </p>
        <div class="section__block">
          <p
            class="section__value yellow glow value text shadow centered">
            {{winningAmountFormatted}}
          </p>
        </div>
        <p
          class="section__details details text lightblue centered">
          {{betAssetName}}
        </p>
      </div>
    </div>
    <div id="tutorial-step-3" class="flex column justify-content-center align-items-center">
      <component :is="component"></component>
      <control-current-game v-show="!isUnavailable"></control-current-game>
    </div>
  </div>
</template>

<script>
import ControlCurrentGame from '@/components/controls/CurrentGame.vue';
import ControlProcessing from '@/components/controls/Processing.vue';
import ControlPlayButton from '@/components/controls/PlayButton.vue';
import SceneBetTypeContainer from '@/components/scene/BetTypesContainer.vue';
import SceneBetMultiplierContainer from '@/components/scene/BetMultiplierContainer.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    ControlCurrentGame,
    ControlProcessing,
    ControlPlayButton,
    SceneBetMultiplierContainer,
    SceneBetTypeContainer,
  },
  computed: {
    ...mapGetters({
      betAssetName: 'betAssetName',
      isProcessing: 'isProcessing',
      isUnavailable: 'isUnavailable',
      winningAmount: 'winningAmount',
    }),
    winningAmountFormatted() {
      return this.winningAmount.toFixed(3)
        .slice(0, -1);
    },
    component() {
      if (this.isProcessing && !this.isUnavailable) {
        return ControlProcessing;
      }

      return ControlPlayButton;
    },
  },
};
</script>

<style lang="scss" scoped>
  .text {
    &.caption {
      font-size: 16px;
      margin: 10px 0px 10px 0px;
    }
  }

  .logo {
    margin: 0px 0px 10px 0px;
  }

  .section {
    &__3 {
      flex: 0 0 calc(100% / 3);
      max-width: calc(100% / 3);
    }

    &__text {
      font-size: 9px;
      margin: 5px 0px 5px 0px
    }

    &__value {
      margin: 0px;
      font-size: 24px;
      &--back {
        color: #fff;
        position: absolute;
        z-index: -1;
        top: 2px;
        right: -2px;
        width: 100%;
      }
    }

    &__block {
      position: relative;
    }

    &__details {
      margin: 0px;
      font-size: 9px;
      color: #fff;
    }

  }

  .flex {
    &.full {
      width: 100%;
    }
  }


  @media screen and (max-width: 1024px) {
    .button {
      &__play {
        margin: 0px;
      }
    }
    .stat {
      &__play {
        margin: 10vh 0px -10px 0px;
      }
    }
  }
  @media screen and (min-height: 780px) {
    .mobile {
      &__main {
        padding: 7vh 0px 0px 0px;
      }
    }
    .stat {
      &__play {
        margin: 20vh 0px -10px 0px;
      }
    }
  }
</style>
