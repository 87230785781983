import ThumbnailWaveBlue from '@/assets/images/thumb-wave-blue.png';
import ThumbnailWaveYellow from '@/assets/images/thumb-wave-yellow.png';
import ThumbnailRocketBlue from '@/assets/images/thumb-rocket-blue.png';
import ThumbnailRocketYellow from '@/assets/images/thumb-rocket-yellow.png';

export default (serverWinner, serverChoice) => {
  if (serverWinner) {
    return [
      serverChoice === 0 ? ThumbnailWaveBlue : ThumbnailRocketBlue,
      serverChoice === 0 ? ThumbnailRocketYellow : ThumbnailWaveYellow,
    ];
  }

  return [
    serverChoice === 0 ? ThumbnailRocketBlue : ThumbnailWaveBlue,
    serverChoice === 0 ? ThumbnailRocketBlue : ThumbnailWaveBlue,
  ];
};
