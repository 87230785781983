<template>
  <div
    :class="['button', { disabled: disabled }, this.currentImage]"
    v-show="isVisible"
    @click="handlePlay"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <!-- <div
      class="flex column align-items-center justify-content-center"
    > -->
        <!-- class="button" -->
        <!-- :class="currentImage" -->
      <!-- <div
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      > -->
        <span class="text white centered">{{title}}</span>
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ConnectOrAction from '../controls/ConnectOrAction.vue';
import * as Popups from '@/utils/popups';

export default {
  components: {
    ConnectOrAction,
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    ...mapGetters({
      isProcessing: 'isProcessing',
      betMultiplier: 'betAmount',
      betType: 'coinSide',
      isAnyPopupOpened: 'isAnyPopupOpened',
      playerAddress: 'playerAddress',
      isPlayBtnDisabled: 'isPlayBtnDisabled',
    }),
    disabled() {
      return this.isProcessing || this.isPlayBtnDisabled;
    },
    isVisible() {
      return !this.isAnyPopupOpened;
    },
    currentImage() {
      if (this.hovered) {
        return 'image-play-hover';
      }
      return 'image-play-base';
    },
    isChoiceDone() {
      return this.betMultiplier != null && this.betType != null;
    },
    title() {
      if(!this.playerAddress) {
        return this.$t('message.connectWallet.title');
      }

      if(this.isProcessing) {
        return this.$t('message.buttons.processing');
      }

      return this.$t('message.buttons.play');
    },
  },
  methods: {
    ...mapActions({
      play: 'play',
      setBackgroundFaded: 'setBackgroundFaded',
      setPopupVisibility: 'setPopupVisibility',
    }),
    handlePlay() {
      if(!this.playerAddress) {
        this.setPopupVisibility({
          popup: Popups.CONNECT_WALLET,
          visible: true,
        })

        return 
      }

      if (this.isChoiceDone) {
        this.play();
      } else {
        this.setBackgroundFaded(true);
      }
    },
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .button {
    padding: 8px;
    width: auto;
    min-width: 130px;
    min-height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/button-big-sprite.png") no-repeat;
    background-size: 115% 100%;
    background-position: center;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 0.8em;
    

    &.disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
      &:hover:not(.disabled) {
        background: url("../../assets/images/button-big-sprite.png") no-repeat;
        background-size: 115% 100%;
        background-position: center;
        opacity: 0.8;
    }

  }
  
  // .button {
  //   $k-ratio: calc(298 / 174);
  //   $button-width: 13vw;
  //   $button-height: calc(#{$button-width} / #{$k-ratio});

  //   @media (min-aspect-ratio: 16/9) {
  //     $button-height-h: 14vh;
  //     $button-width-h: calc(#{$button-height-h} * #{$k-ratio});

  //     width: $button-width-h !important;
  //     height: $button-height-h !important;


  //     .text {
  //       font-size: 2.0vh !important;
  //     }
  //   }

  //   width: $button-width;
  //   height: $button-height;
  //   display: block;
  //   background: url("../../assets/images/button-big-sprite.png") 0 0 no-repeat;
  //   background-size: contain;
  //   background-position: center;
  //   cursor: pointer;

  //   min-width: 130px;
  //   text-align: center;
  //   text-transform: uppercase;

  //   .text {
  //     font-size: calc((9 / 16) * 2vw);
  //   }

  //   &.disabled {
  //     opacity: 0.5;
  //     cursor: default;
  //   }
  //       &:hover:not(.disabled) {
  //         width: $button-width;
  //         height: $button-height;
  //         background: url("../../assets/images/button-big-sprite.png") 100% 0 no-repeat;
  //         background-size: contain;
  //         background-position: center;

  //     span {
  //       opacity: 0.8;
  //     }
  //   }

  //   span {
  //     display: block;
  //     width: $button-width;
  //     line-height: $button-height;
  //   }

  // }

  // @media screen and (max-width: 1024px) {
  //   .processing {
  //     padding: 26px 4px 2px 4px;
  //     font-size: 6vw !important;
  //   }
  //   .play {
  //     height: auto;
  //   }
  //   .button {
  //     width: auto !important;
  //     height: auto !important;
  //     outline: none;
  //     -webkit-tap-highlight-color: rgba(0,0,0,0);
  //     -webkit-tap-highlight-color: transparent;
  //     .text {
  //       font-size: 16px !important;
  //       padding: 45px 50px 45px 50px !important;
  //       width: auto !important;
  //       line-height: 10px !important;
  //     }
  //   }
  // }

</style>
