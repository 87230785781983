/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import urlJoin from 'url-join';
import config from '@/config';
import { jsonHeaders } from '@/utils';

export const getStatsLeaderboard = async (gameName = '') => {
  const response = await fetch(urlJoin(
    config.api.backend.url,
    config.api.backend.statsGameEndpoint,
    `/leaders?gameName=${gameName}&limit=100`,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};

export const getStatsLastGames = async (gameName = '') => {
  const response = await fetch(urlJoin(
    config.api.backend.url,
    config.api.backend.statsGameEndpoint,
    `/last?gameName=${gameName}`,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};

export const getStatsLastGamesByAddress = async (gameName = '', playerAddress) => {
  const response = await fetch(urlJoin(
    config.api.backend.url,
    config.api.backend.statsGameEndpoint,
    `/last?gameName=${gameName}&playerAddress=${playerAddress}`,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};

export const getStatsTotal = async (gameName = '') => {
  const response = await fetch(urlJoin(
    config.api.backend.url,
    config.api.backend.statsGameEndpoint,
    `?gameName=${gameName}`,
  ), {
    method: 'GET',
    headers: jsonHeaders,
  });

  return response.json();
};
