<template>
  <transition key="t-current-game" name="fade" mode="out-in">
    <div v-if="visible" class="mobile__current">
      <div class="current flex justify-content-center align-items-center">
        <transition name="fade" mode="out-in">
          <div
            key="t-process"
            v-if="isWinnerIsUndefined"
            class="container-wrapper flex column align-items-center"
          >
            <ul>
              <li class="flex row mobile__block">
                <div class="title">ID:</div>
                <div>
                  <a target="_blank" href="#" @click.prevent="copyGameId">
                    {{currentGameId}}
                    <span class="copy">(copy)</span>
                  </a>
                </div>
              </li>
              <li class="flex row mobile__block">
                <div class="title">{{$t('message.gameInfoSelected.bet')}}:</div>
                <div>
                  {{currentGameBetAmount}}
                  <div class="icon" :class="`icon__${betAssetTicker}`"></div>
                </div>
              </li>
              <li class="flex row mobile__block">
                <div class="title">{{$t('message.gameInfoSelected.playerChoice')}}:</div>
                <img
                  class="thumbnail"
                  src="../../assets/images/thumb-rocket-blue.png"
                  alt
                  v-if="!currentGameCoinSide"
                />
                <img class="thumbnail" src="../../assets/images/thumb-wave-blue.png" alt v-else />
              </li>
            </ul>
            <div class="flex row buttons">
              <a target="_blank" :href="smartAddressLink">
                <control-small-button
                  class="smart__link"
                  :title="$t('message.gameInfoSelected.smartContract')"
                ></control-small-button>
              </a>
            </div>
          </div>
          <div key="t-winner" v-else class="winner white text caption centered">
            <span @click="closeCurrent" class="current__close">
              <CloseMobile />
            </span>
            <div v-if="isWinnerPlayer">{{$t('message.won.title')}}</div>
            <div v-else>{{$t('message.lost.title')}}</div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import urlJoin from 'url-join';
import config from '@/config';
import { addressTruncate, ProcessSteps, States } from '@/utils';
import ControlSmallButton from '@/components/controls/SmallButton.vue';

import CloseMobile from '@/assets/images/CloseMobile.svg';

export default {
  components: {
    ControlSmallButton,
    CloseMobile,
  },
  props: {
    title: String,
  },
  computed: {
    ...mapGetters({
      betAmount: 'betAmount',
      playerAddress: 'playerAddress',
      selectedDices: 'selectedDices',
      processingStep: 'processingStep',
      betAssetId: 'betAssetId',
      betAssetTicker: 'betAssetTicker',
      gameInfoCurrent: 'gameInfoCurrent',
    }),
    visible() {
      return this.processingStep >= ProcessSteps.PLACE_BET && this.currentGameId;
    },
    currentGameId() {
      const { gameId } = this.gameInfoCurrent;
      return addressTruncate(gameId, 7, 7, '***');
    },
    currentGameBetAmount() {
      const { betAmount } = this.gameInfoCurrent;
      return betAmount;
    },
    currentGameCoinSide() {
      const { coinSide } = this.gameInfoCurrent;
      return coinSide;
    },
    playerAddressLink() {
      return urlJoin(
        config.api.explorer.url,
        config.api.explorer.address,
        this.playerAddress,
      );
    },
    smartAddressLink() {
      return urlJoin(
        config.api.explorer.url,
        config.api.explorer.address,
        config.dapp.address,
      );
    },
    isWinnerIsUndefined() {
      return this.gameInfoCurrent.winner === null;
    },
    isWinnerPlayer() {
      return this.gameInfoCurrent.winner === States.WON;
    },
  },
  methods: {
    ...mapActions({
      closeCurrentGame: 'closeCurrentGame',
    }),
    closeCurrent() {
      this.closeCurrentGame();
    },
    async copyGameId() {
      const { gameId } = this.gameInfoCurrent;
      await navigator.clipboard.writeText(gameId);
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.icon__WAVES {
  background-image: url("../../assets/images/wave-small.png");
}
.icon__XTN {
  background-image: url("../../assets/images/XTN_icon.png");
}

.mobile {
  &__current {
    display: contents;
  }
}

.current {
  bottom: 0;
  position: absolute;
  background: url("../../assets/images/window-small.png") no-repeat center;
  background-size: contain;
  height: 10vw;
  width: 18vw;

  &__close {
    display: none;
  }

  .thumbnail {
    height: 1vw;
    padding-left: 0.2vw;
  }

  .copy {
    padding: 0 0.3vw;
    font-size: 0.5vw;
    font-family: Arial, sans-serif;
  }

  @media (min-aspect-ratio: 16/9) {
    width: calc((16 / 9) * 18vh) !important;
    height: calc((16 / 9) * 10vh) !important;

    .thumbnail {
      height: calc((16 / 9) * 1vh);
      padding-left: calc((16 / 9) * 0.2vh);
    }

    .copy {
      padding: 0 calc((16 / 9) * 0.3vh);
      font-size: calc((16 / 9) * 0.5vh);
    }

    .content {
      width: calc((16 / 9) * 30vh) !important;
      margin-top: calc((16 / 9) * 1vh) !important;
    }

    .container-wrapper {
      margin-top: calc((16 / 9) * 1vh) !important;

      ul {
        margin: 0;

        li {
          height: calc((16 / 9) * 1.6vh) !important;
          font-size: calc((16 / 9) * 1vh) !important;
          letter-spacing: calc((16 / 9) * 0.03vh) !important;

          &.bigger {
            font-size: calc((16 / 9) * 1.2vh) !important;
          }

          div:last-child {
            padding-left: calc((16 / 9) * 0.5vh) !important;
          }
        }
      }
    }
  }

  .content {
    width: 30vw;
    margin-top: 1vw;
  }

  .container-wrapper {
    margin-top: 1vw;

    a:hover {
      color: rgb(125, 202, 250);
    }

    ul {
      list-style-type: none;
      text-transform: uppercase;
      margin: 0;
      padding-left: 0;

      li {
        height: 1.6vw;
        display: flex;
        align-items: center;
        font-size: 1vw;
        letter-spacing: 0.03vw;

        &.bigger {
          font-size: 1.2vw;
        }

        div:first-child {
          color: rgb(125, 202, 250);
        }

        div:last-child {
          color: white;
          padding-left: 0.5vw;
        }
      }
    }

    .buttons {
      a {
        text-decoration: none;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .buttons {
    height: auto;
    justify-content: center;
    .button {
      padding: 18px;
      height: auto;
      margin: -8px 0px -8px 0px;
      width: auto;
    }
  }

  .mobile {
    &__current {
      display: block;
      &:before {
        content: "";
        position: absolute;
        background-color: #000;
        opacity: 0.5;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
    &__block {
      font-size: 12px !important;
      height: auto !important;
      text-align: center;
      margin-bottom: 10px;
      justify-content: center;
      .wave {
        width: auto;
      }
      .copy {
        font-size: 12px;
      }
      .thumbnail {
        height: 20px;
        margin-left: 5px;
      }
      &--wrap {
        flex-wrap: wrap;
        div {
          width: 100%;
          margin-bottom: 5px;
          justify-content: center;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .current {
    height: 38vh;
    width: calc(100vw - 20px);
    bottom: 5vh;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("../../assets/images/window-big.png") no-repeat center;
    background-size: contain;
    &__close {
      display: block;
      position: absolute;
      right: 40px;
      top: 70px;
    }
    .text {
      &.caption {
        font-size: 18px;
      }
    }
  }
}
</style>
