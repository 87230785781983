<template>
  <popup
    :visible="visible"
    :closable="true"
    :title="$t('message.information.title')"
    :no-title="true"
    @close="hidePopup"
  >
    <div class="container flex column stretch">
      <div class="desktop-popup">
        <div class="text white centered shadow">
          {{ $t('message.connectWallet.title') }}
        </div>
        <div class="flex row buttons">
          <button-wide :title="$t('message.connectWallet.email.title')"
                       :label="$t('message.connectWallet.email.label')" :icon="'Email'" :variant="1"
                       @click="onEmailClick"></button-wide>
        </div>
        <div class="flex row buttons">
          <button-wide :title="$t('message.connectWallet.seed.title')"
                       :label="$t('message.connectWallet.seed.label')" :icon="'Seed'" :variant="0"
                       @click="onSeedClick"></button-wide>
        </div>
        <div v-if="hasKeeper()" class="flex row buttons">
          <button-wide :title="$t('message.connectWallet.keeper.title')"
                       :label="$t('message.connectWallet.keeper.label')" :icon="'Keeper'"
                       :variant="0" @click="onKeeperClick"></button-wide>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ButtonWide from '@/components/controls/ButtonWide.vue';
import Popup from '@/components/popups/Popup.vue';
import { Popups } from '@/utils';

export default {
  components: {
    Popup,
    ButtonWide,
  },
  computed: {
    ...mapGetters({
      popupsVisibility: 'popupsVisibility',
    }),
    visible() {
      return this.popupsVisibility[Popups.CONNECT_WALLET];
    },
  },
  methods: {
    ...mapActions({
      setPopupVisibility: 'setPopupVisibility',
      logIn: 'logIn',
    }),
    hasKeeper() {
      return window.WavesKeeper !== undefined;
    },
    hidePopup() {
      this.setPopupVisibility({
        popup: Popups.CONNECT_WALLET,
        visible: false,
      });
    },
    onEmailClick() {
      this.hidePopup();
      this.logIn('email');
    },
    onSeedClick() {
      this.hidePopup();
      this.logIn('seed');
    },
    onKeeperClick() {
      this.hidePopup();
      this.logIn('keeper');
    },
  },
};
</script>

<style scoped lang="scss">

.container {
  font-family: "Serpentin", sans-serif;

  .buttons {
    margin: 1vh 0;
  }
}

.desktop-popup {
  display: block;
  width: 400px;
  @media (min-aspect-ratio: 16/9) {
    width: 24vw;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: auto;
    .text.caption {
      margin: auto;
      width: 80%;
    }
  }
}

</style>
