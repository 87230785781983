import { render, staticRenderFns } from "./AppMobile.vue?vue&type=template&id=630e9c46&"
import script from "./AppMobile.vue?vue&type=script&lang=js&"
export * from "./AppMobile.vue?vue&type=script&lang=js&"
import style0 from "./AppMobile.vue?vue&type=style&index=0&id=630e9c46&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports