
<template>
  <div
    class="w-100vw h-100vh flex row justify-content-center
          align-items-center wh-100-aspect absolute scaled">
    <div class="content flex column justify-content-center align-items-center" style="height: 70%">
      <player-address
        v-if="!!playerAddress"
        className="connect-wallet_top"
      ></player-address>

      <div class="flex row" style="height: 90%">
        <div
          class="flex column"
          :class="{ unavailable: isUnavailable }"
        >
          <scene-left-block></scene-left-block>
        </div>
        <div
          class="flex column align-items-center"
          :class="{ unavailable: isUnavailable }"
        >
          <div class="flex column justify-content-center align-items-center">
            <div id="tutorial-step-1">
              <scene-bet-type-container></scene-bet-type-container>
            </div>
            <div id="tutorial-step-2">
              <scene-bet-multiplier-container></scene-bet-multiplier-container>
            </div>
          </div>
          <div id="tutorial-step-3" class="flex column justify-content-center align-items-center">
            <component :is="component"></component>
            <control-current-game v-show="!isUnavailable"></control-current-game>
          </div>
        </div>
        <div class="flex row">
          <scene-right-block></scene-right-block>
          <control-locale-changer></control-locale-changer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControlCurrentGame from '@/components/controls/CurrentGame.vue';
import ControlProcessing from '@/components/controls/Processing.vue';
import ControlPlayButton from '@/components/controls/PlayButton.vue';
import SceneLeftBlock from '@/components/scene/LeftBlock.vue';
import SceneRightBlock from '@/components/scene/RightBlock.vue';
import SceneBetTypeContainer from '@/components/scene/BetTypesContainer.vue';
import SceneBetMultiplierContainer from '@/components/scene/BetMultiplierContainer.vue';
import ControlLocaleChanger from '@/components/controls/LocaleChanger.vue';
import PlayerAddress from '@/components/ui/Address';
import { mapGetters } from 'vuex';

export default {
  components: {
    PlayerAddress,
    ControlCurrentGame,
    ControlProcessing,
    ControlPlayButton,
    ControlLocaleChanger,
    SceneBetMultiplierContainer,
    SceneBetTypeContainer,
    SceneLeftBlock,
    SceneRightBlock,
  },
  computed: {
    ...mapGetters({
      playerAddress: 'playerAddress',
      isProcessing: 'isProcessing',
      isUnavailable: 'isUnavailable',
    }),
    component() {
      if (this.isProcessing && !this.isUnavailable) {
        return ControlProcessing;
      }

      return ControlPlayButton;
    },
  },
};
</script>

<style lang="scss" scoped>

  .content {
    position: relative;
  }

  .connect-wallet_top {
    position: absolute;
    top: -15%;
    right: 0;
  }

  .unavailable {
    pointer-events: none;
  }
</style>
