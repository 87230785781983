<template>
  <div :class="{'shadow-off': backgroundFaded && !isBetMultiplierSet}">
    <div class="asset-select-section">
      <p
        :class="captionStyle"
        class="text caption white mobile__title"
      >{{ $t("message.makeYourBetNow") }}</p>

      <my-select
        :options="assets"
        label="ticker"
        :disabled="isProcessing"
        :value="betAssetId"
        @onselected="onSelect"
      ></my-select>

    </div>
    <div class="flex row justify-content-center">
      <bet-multiplier-chooser :factor="betMultiplier.X1"></bet-multiplier-chooser>
      <bet-multiplier-chooser :factor="betMultiplier.X2"></bet-multiplier-chooser>
      <bet-multiplier-chooser :factor="betMultiplier.X4"></bet-multiplier-chooser>
      <bet-multiplier-chooser :factor="betMultiplier.X8"></bet-multiplier-chooser>
      <bet-multiplier-chooser :factor="betMultiplier.X12"></bet-multiplier-chooser>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BetMultiplier } from '@/models';
import { assets } from '@/utils';
import BetMultiplierChooser from '@/components/controls/BetMultiplierChooser.vue';
import MySelect from '@/components/ui/Select.vue';

export default {
  components: {
    MySelect,
    BetMultiplierChooser,
  },
  data: () => ({
    assets,
  }),
  computed: {
    ...mapGetters({
      currentBetMultiplier: 'betAmount',
      isProcessing: 'isProcessing',
      backgroundFaded: 'backgroundFaded',
      coinSie: 'coinSide',
      betAssetId: 'betAssetId',
      gameInfoCurrent: 'gameInfoCurrent',
    }),
    betAssetId: {
      set(v) {
        this.setBetAssetId(v);
      },
      get() {
        return this.gameInfoCurrent.betAssetId;
      },
    },
    isBetMultiplierSet() {
      return this.currentBetMultiplier !== null;
    },
    betMultiplier() {
      return BetMultiplier;
    },
    captionStyle() {
      return ['caption text uppercase white glow', {
        'shadow-off': this.backgroundFaded && !this.isBetMultiplierSet,
        flicker: this.backgroundFaded && !this.isBetMultiplierSet,
      }];
    },
  },
  methods: {
    ...mapActions({
      setBackgroundFaded: 'setBackgroundFaded',
      setBetAssetId: 'setBetAssetId',
    }),
    onSelect(selectedItem) {
      this.setBetAssetId(selectedItem.id);
    }
  },
};
</script>

<style lang="scss" scoped>
.asset-select-section {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: auto;

  & select {
    font-size: 16px;
  }
}


@media screen and (max-width: 1024px) {
.asset-select-section {
  margin-top: 30px;
  margin-bottom: 20px;
}
  select {
    background: linear-gradient(white, rgb(112, 175, 244));
    border:none;
    border-radius: 5px;
    padding: 7px;
    font-size: 10px;
    color: white;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
    -webkit-appearance: none;
}

  .mobile {
    .mobile__title {
      font-size: 16px;
    }
  }
}
</style>
